import React from "react"

import styled from "styled-components"

const FeatureVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 40.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  border-bottom: 0.4rem solid #edc16e;

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .videoOverLay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 6;
    top: 0;
  }
`

const FeatureVidoe = props => {
  return (
    <>
      <FeatureVideo>
        <div className="videoOverLay"></div>
        <video
          className="responsive-iframe"
          controls
          muted
          autoPlay
          loop
          playsInline
          src={props.featVideo}
        />
      </FeatureVideo>
    </>
  )
}

export default FeatureVidoe
